import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@iconify-icon/react';
import useAssistant from '@/assistant/hooks/useAssistant';
import {
  COMMAND_IDENTIFIERS,
  COMMAND_LANGUAGES_WITH_AUTODETECT,
} from '@/assistant/constants/Constants';
import StoryChief from '@/storychief';
import useBrandVoices from '@/assistant/hooks/useBrandVoices';
import useAccount from '@/account/hooks/useAccount';
import CanWrapper from '@/storychief/components/CanWrapper';
import DropdownMenu from '@/storychief/components/DropdownMenu';

const propTypes = {
  insidePopover: PropTypes.bool,
};
const defaultProps = {
  insidePopover: false,
};

function CommandAssistantConfig({ insidePopover }) {
  const account = useAccount();
  const { command, config, language, setLanguage } = useAssistant();

  const { data: { brandVoices } = { brandVoices: [] } } = useBrandVoices();

  const currentBrandVoice = useMemo(
    () => brandVoices.find((_b) => _b.id === config.local.data.brandVoice),
    [config, JSON.stringify(brandVoices)],
  );

  function handleOnLanguageChange(_language) {
    setLanguage(_language);
  }

  function handleOnBrandVoiceChange(voice) {
    config.local.set({ brandVoice: voice?.id || null });
  }

  function canUseBrandVoice() {
    return ![
      COMMAND_IDENTIFIERS.SPELLING,
      COMMAND_IDENTIFIERS.EXPAND,
      COMMAND_IDENTIFIERS.STORY_META_DESCRIPTION,
      COMMAND_IDENTIFIERS.STORY_META_TITLE,
      COMMAND_IDENTIFIERS.TRANSLATE,
      COMMAND_IDENTIFIERS.CHANGE_TONE,
    ].includes(command.identifier);
  }

  function canTranslate() {
    if (!insidePopover && COMMAND_IDENTIFIERS.CHAT === command.identifier) {
      // Still show the translation drop-down inside the text editor.
      // Else they need to press translate -> change language -> press "Try again"
      return false;
    }

    // noinspection RedundantIfStatementJS
    if (!insidePopover && COMMAND_IDENTIFIERS.TRANSLATE === command.identifier) {
      // At /free/assistant/content-translate we use a select-box,
      // while in a story or social post we use a drop-down
      return false;
    }

    return true;
  }

  return (
    <div className="command-assistant-config d-flex flex-align-items-start flex-gap-md">
      {canUseBrandVoice() && (
        <DropdownMenu
          trigger={
            <button
              type="button"
              className="btn-chromeless no-space"
              data-testid="command-form-button-brand-voice"
              aria-label="Open menu"
            >
              <Icon icon="custom:megaphone" inline className="mr-1" />{' '}
              <span className="text-normal">{currentBrandVoice?.name || 'none'}</span>{' '}
            </button>
          }
        >
          <DropdownMenu.Item>
            <button
              className="btn btn-chromeless"
              type="button"
              onClick={() => handleOnBrandVoiceChange()}
            >
              none
            </button>
          </DropdownMenu.Item>
          {brandVoices.map((_voice) => (
            <DropdownMenu.Item key={_voice.id}>
              <button
                className="btn btn-chromeless"
                type="button"
                onClick={() => handleOnBrandVoiceChange(_voice)}
              >
                {_voice.name}
              </button>
            </DropdownMenu.Item>
          ))}

          <CanWrapper object={account} ability="brand_voices">
            <DropdownMenu.Item>
              <a
                href={`${StoryChief.dashboardBasePath}/assistant/config/company-intelligence`}
                target="blank"
                key="voice-add"
              >
                Create new brand voice
              </a>
            </DropdownMenu.Item>
          </CanWrapper>
        </DropdownMenu>
      )}
      {canTranslate() && (
        <DropdownMenu
          className="dropdown-menu--command-language"
          trigger={
            <button
              type="button"
              className="btn-chromeless no-space"
              aria-label="Open menu"
              data-testid="command-form-button-language"
            >
              <span className="text-truncate">
                <Icon icon="fa:globe" inline className="mr-1" /> <span>{language || 'auto'}</span>
              </span>
            </button>
          }
        >
          {COMMAND_LANGUAGES_WITH_AUTODETECT.map((_language) => (
            <DropdownMenu.Item key={_language.value}>
              <button
                className="btn btn-chromeless"
                type="button"
                onClick={() => handleOnLanguageChange(_language.value)}
              >
                {_language.label}
              </button>
            </DropdownMenu.Item>
          ))}
        </DropdownMenu>
      )}
    </div>
  );
}

CommandAssistantConfig.propTypes = propTypes;
CommandAssistantConfig.defaultProps = defaultProps;

export default CommandAssistantConfig;

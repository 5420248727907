import { useHistory, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';
import { EditorState } from 'draft-js';
import { DropdownButton, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Icon } from '@iconify-icon/react';
import AssistantDropdownMenuItems from './AssistantDropdownMenuItems';
import useAssistant from '../hooks/useAssistant';
import { ASSISTANT_URL_SEARCH_PARAMS, COMMANDS } from '../constants/Constants';
import getRecommendedDraftCommandsByType from '../utils/getRecommendedDraftCommandsByType';
import ConditionalWrapper from '@/storychief/components/ConditionalWrapper';
import RestrictedButton from '@/storychief/components/RestrictedButton';
import useSearchParamsWithRouter from '@/storychief/hooks/useSearchParamsWithRouter';
import useAccount from '@/account/hooks/useAccount';

const propTypes = {
  getEditorState: PropTypes.func,
  setEditorState: PropTypes.func,
  disabled: PropTypes.bool,
  includeMenu: PropTypes.bool,
  className: PropTypes.string,
  pullRight: PropTypes.bool,
  showTooltip: PropTypes.bool,
  title: PropTypes.string,
  onEntitlementPopoverShow: PropTypes.func,
  onEntitlementPopoverHide: PropTypes.func,
};

const defaultProps = {
  getEditorState: undefined,
  setEditorState: undefined,
  disabled: false,
  includeMenu: true,
  className: '',
  title: 'AI',
  pullRight: false,
  showTooltip: true,
  onEntitlementPopoverShow: () => {},
  onEntitlementPopoverHide: () => {},
};

const entitlementDescription = 'Purchase the AI Power Mode add-on to activate its features.';
const entitlement = 'ai-assistant';

function AssistantDropdown({
  getEditorState,
  setEditorState,
  disabled,
  includeMenu,
  className,
  pullRight,
  showTooltip,
  title,
  onEntitlementPopoverShow,
  onEntitlementPopoverHide,
}) {
  const account = useAccount();
  const history = useHistory();
  const location = useLocation();
  const {
    initCommand,
    subject,
    available,
    isOverMonthlyLimit,
    resetCommand,
    runCommand,
    command,
    input,
    context,
  } = useAssistant();
  const [dropdownKey, setDropdownKey] = useState(0);
  const recommendedDraftCommands = useMemo(
    () => getRecommendedDraftCommandsByType(subject),
    [subject],
  );

  const { getUrlSearchParam, deleteUrlSearchParam } = useSearchParamsWithRouter();

  useEffect(() => {
    const commandFromSearch = getUrlSearchParam(ASSISTANT_URL_SEARCH_PARAMS.initCommand);

    if (commandFromSearch) {
      initCommand(COMMANDS[commandFromSearch]);
      if (available && command) {
        handleRunCommand();
        deleteUrlSearchParam(ASSISTANT_URL_SEARCH_PARAMS.initCommand);
        deleteUrlSearchParam(ASSISTANT_URL_SEARCH_PARAMS.workingTitle);
      }
    }
  }, [location, history, available, command]);

  function handleRunCommand() {
    if (available && !isOverMonthlyLimit) {
      resetCommand(command, input, context, null);
      runCommand(command, input, context, null, null);
    }
  }

  function handleOnFocus(e) {
    if (e) {
      e.preventDefault();
    }
    if (getEditorState && setEditorState) {
      // Fix to prevent editor from loosing selection
      const editorState = getEditorState();
      const newEditorState = EditorState.forceSelection(
        getEditorState(),
        editorState.getSelection(),
      );
      setEditorState(newEditorState);
    }
  }

  function handleOnCommandClick(_command, _context) {
    setDropdownKey((prev) => prev + 1); // hide dropdown items
    initCommand(_command, { _context });
  }

  function handleOnButtonClick() {
    const isEmpty = typeof subject.isEmpty === 'undefined' || subject.isEmpty;
    const isReplaceDropdownWithSocialPostPrompt = isEmpty && recommendedDraftCommands.length === 1;

    initCommand(
      isReplaceDropdownWithSocialPostPrompt ? recommendedDraftCommands[0] : COMMANDS.CHAT,
    );
  }

  const dropdownTitle = useMemo(
    () => (
      <ConditionalWrapper
        condition={showTooltip}
        wrapper={(children) => (
          <OverlayTrigger
            placement="top"
            delayShow={100}
            overlay={<Tooltip id="assistant-dropdown-tooltip">AI Power Mode</Tooltip>}
          >
            {children}
          </OverlayTrigger>
        )}
      >
        <div className="dropdown-title d-flex flex-align-items-center">
          <div>
            <Icon icon="fa:rocket" inline width="12" height="12" className="mr-1 w-[12px]" />
          </div>
          <div>
            {subject?.isEmpty &&
            recommendedDraftCommands.length === 1 &&
            recommendedDraftCommands[0].autoRun
              ? 'Auto-fill'
              : title}
          </div>
        </div>
      </ConditionalWrapper>
    ),
    [subject],
  );

  if (!account.can.power_mode) {
    return null;
  }

  if (!includeMenu || !available) {
    return (
      <RestrictedButton
        type="button"
        id="assistantDropdown"
        disabled={disabled}
        onMouseDown={handleOnFocus}
        className={`assistant-dropdown ${className}`}
        onClick={handleOnButtonClick}
        entitlement={entitlement}
        tooltipPlacement="top"
        entitlementDescription={entitlementDescription}
        onEntitlementPopoverShow={onEntitlementPopoverShow}
        onEntitlementPopoverHide={onEntitlementPopoverHide}
      >
        {dropdownTitle}
      </RestrictedButton>
    );
  }
  return (
    <DropdownButton
      id="assistantDropdown"
      disabled={disabled}
      title={dropdownTitle}
      noCaret
      onMouseDown={handleOnFocus}
      bsStyle={null}
      className={`assistant-dropdown ${className}`}
      pullRight={pullRight}
      key={dropdownKey}
    >
      <AssistantDropdownMenuItems
        getEditorState={getEditorState}
        setEditorState={setEditorState}
        pullRight={pullRight}
        onCommandClick={handleOnCommandClick}
      />
    </DropdownButton>
  );
}

AssistantDropdown.propTypes = propTypes;
AssistantDropdown.defaultProps = defaultProps;

export default AssistantDropdown;

import { Button, FormGroup, OverlayTrigger, Tooltip } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';
import uuid from 'uuid/v4';
import ScrollToBottom from 'react-scroll-to-bottom';
import { Icon } from '@iconify-icon/react';
import useAssistant from '@/assistant/hooks/useAssistant';
// The dependency cycle is caused by <RichEditor> importing assistant components and those components also importing <RichEditor>.
// The issue is mitigated by disabling the assistant on <RichEditor> inside the assistant components.
// eslint-disable-next-line import/no-cycle
import TextEditor from '@/storychief/components/editors/TextEditor';
import ConditionalWrapper from '@/storychief/components/ConditionalWrapper';
import PointerEventsWrapper from '@/storychief/components/PointerEventsWrapper';
import { COMMANDS, COMMAND_SECTIONS } from '@/assistant/constants/Constants';
import RestrictedButton from '@/storychief/components/RestrictedButton';
import CommandResultTranscriptItem from './CommandResultTranscriptItem';
import analyticsTrack from '@/storychief/utils/analyticsTrack';

const propTypes = {
  insidePopover: PropTypes.bool,
  size: PropTypes.oneOf(['sm', 'lg']),
};

const defaultProps = {
  insidePopover: false,
  size: undefined,
};

function CommandResult({ insidePopover, size }) {
  const {
    result,
    setResult,
    context,
    subject,
    input,
    loading,
    command,
    acceptCommand,
    cancelCommand,
    retryCommand,
    getEditorState,
    discardCommand,
    isOverMonthlyLimit,
    maxMonthlyUsage,
    runCommand,
    available,
  } = useAssistant();
  const [chatInput, setChatInput] = useState('');
  const [selectedEditorStates, setSelectedEditorStates] = useState([]);
  const [currentResponseIndex, setCurrentResponseIndex] = useState(0);

  const transcriptAssistantIndexes = useMemo(
    () =>
      result?.transcript?.flatMap((item, index) => (item.role === 'assistant' ? [index] : [])) ||
      [],
    [result],
  );

  useEffect(() => {
    setCurrentResponseIndex(
      transcriptAssistantIndexes?.length ? transcriptAssistantIndexes.length - 1 : 0,
    );
  }, [transcriptAssistantIndexes]);

  useEffect(() => {
    document.addEventListener('keydown', onKeyPress);
    return () => {
      document.removeEventListener('keydown', onKeyPress);
    };
  }, []);

  function onKeyPress(event) {
    if (event.keyCode === 27) {
      event.preventDefault();
      cancelCommand();
    }
  }

  function handleOnChatInputChange(_input) {
    setChatInput(_input);
  }

  /**
   * The user used a prompt such as "job posting" and the user presses "Ask AI what to do next"
   */
  function handleOnChatSubmitClick() {
    if (!chatInput) {
      return;
    }

    const _result = { ...result };
    _result.transcript.length = transcriptAssistantIndexes[currentResponseIndex] + 1;
    _result.transcript.push({ role: 'user', content: chatInput, id: uuid() });
    setResult(_result);
    runCommand(COMMANDS.CHAT, input, context, _result);
    setChatInput('');
    analyticsTrack('Assistant Command Continued', {
      command: command.identifier,
      continue_type: 'chat',
      subject_type: subject?.type || null,
    });
  }

  function handleOnContinueCommand() {
    const _result = { ...result };
    _result.transcript.length = transcriptAssistantIndexes[currentResponseIndex] + 1;
    _result.transcript.push({ role: 'user', content: 'continue', id: uuid() });
    setResult(_result);
    runCommand(command, input, context, _result);
    analyticsTrack('Assistant Command Continued', {
      command: command.identifier,
      continue_type: 'continue generating',
      subject_type: subject?.type || null,
    });
  }

  function handleOnTranscriptPagination(direction) {
    setCurrentResponseIndex((prevIndex) => {
      const _prevIndex = prevIndex || 0;
      if (direction === 'prev' && _prevIndex > 0) {
        return _prevIndex - 1;
      }
      if (direction === 'next' && _prevIndex < transcriptAssistantIndexes.length - 1) {
        return _prevIndex + 1;
      }
      return _prevIndex;
    });
  }

  function getResultContent() {
    if (selectedEditorStates.length > 0) {
      return selectedEditorStates
        .map((s) => s.editorState.getCurrentContent().getPlainText('\u0001'))
        .join('');
    }

    return result.transcript[transcriptAssistantIndexes[currentResponseIndex]].content;
  }

  function handleOnAcceptCommand(insertBelow = false) {
    acceptCommand(getResultContent(), insertBelow);
  }

  function handleOnRetryCommand() {
    handleOnTranscriptPagination('prev');
    retryCommand();
  }

  function renderTranscript() {
    if (result?.transcript.length) {
      if (insidePopover) {
        return (
          <CommandResultTranscriptItem
            size={size}
            item={result.transcript[transcriptAssistantIndexes[currentResponseIndex]]}
            insidePopover={insidePopover}
            lastItem
            subject={subject}
            loading={loading}
            onRetry={handleOnRetryCommand}
            onContinue={handleOnContinueCommand}
            selectedEditorStates={selectedEditorStates}
            onSelectedEditorStatesChange={setSelectedEditorStates}
          />
        );
      }
      return result.transcript
        .slice(0, transcriptAssistantIndexes[currentResponseIndex] + 1)
        .map((item, i, row) => (
          <CommandResultTranscriptItem
            size={size}
            key={item.id}
            item={item}
            insidePopover={insidePopover}
            lastItem={i + 1 === row.length}
            loading={loading && i + 1 === row.length}
            onRetry={handleOnRetryCommand}
            onContinue={handleOnContinueCommand}
            selectedEditorStates={selectedEditorStates}
            onSelectedEditorStatesChange={setSelectedEditorStates}
          />
        ));
    }
    return null;
  }

  if (!result?.transcript?.length) {
    return null;
  }

  return (
    <div className="command-result h-100">
      <ScrollToBottom
        followButtonClassName="btn--scroll-to-bottom"
        className="command-result__transcript"
        initialScrollBehavior="auto"
      >
        {renderTranscript()}
      </ScrollToBottom>
      {!loading && (
        <div className="command-result__actions">
          <FormGroup controlId="chat" bsSize={size} className="m-0">
            <div className="command-result__actions__chat d-flex flex-gap-lg space-2 flex-row">
              <TextEditor
                allowNewLines
                focusOnMount
                placeholder="Ask AI what to do next"
                readOnly={loading}
                content={chatInput}
                onContentChange={handleOnChatInputChange}
                onReturn={handleOnChatSubmitClick}
                formControl
                formControlAutoHeight
                formControlId="input"
                assistant={false}
                debounceContentChange={false}
              />
              <div className="d-flex flex-align-items-end">
                <ConditionalWrapper
                  condition={available && isOverMonthlyLimit}
                  wrapper={(children) => (
                    <OverlayTrigger
                      key="command-submit-overlay"
                      placement="top"
                      overlay={
                        <Tooltip id="command-submit-tooltip">
                          The monthly limit of {maxMonthlyUsage} has been reached. You can upgrade
                          to AI Power Mode Pro anytime in your billing center.
                        </Tooltip>
                      }
                    >
                      <PointerEventsWrapper>{children}</PointerEventsWrapper>
                    </OverlayTrigger>
                  )}
                >
                  <RestrictedButton
                    onClick={handleOnChatSubmitClick}
                    disabled={loading || isOverMonthlyLimit}
                    className={`btn btn-primary ${size ? `btn-${size}` : ''}`}
                    bsSize={size}
                    entitlement="ai-assistant"
                    tooltipPlacement={insidePopover ? 'top' : 'bottom'}
                    entitlementDescription="Purchase the AI Power Mode add-on to activate its features."
                  >
                    <Icon icon="fa:paper-plane-o" />
                  </RestrictedButton>
                </ConditionalWrapper>
              </div>
              {transcriptAssistantIndexes?.length > 1 && (
                <div className="d-flex flex-align-items-center flex-row">
                  <div
                    role="button"
                    tabIndex="0"
                    onKeyDown={() => handleOnTranscriptPagination('prev')}
                    onClick={() => handleOnTranscriptPagination('prev')}
                  >
                    <em className="icon-angle-left" />
                  </div>
                  <div className="d-flex text-muted small flex-row">
                    <div>{currentResponseIndex + 1}</div>
                    <div>/</div>
                    <div>{transcriptAssistantIndexes.length}</div>
                  </div>
                  <div
                    role="button"
                    tabIndex="0"
                    onKeyDown={() => handleOnTranscriptPagination('next')}
                    onClick={() => handleOnTranscriptPagination('next')}
                  >
                    <em className="icon-angle-right" />
                  </div>
                </div>
              )}
            </div>
          </FormGroup>

          {insidePopover && (
            <div className="command-result-footer d-flex flex-align-center space-top-2">
              <div className="d-flex flex-align-center flex-grow">
                {!!(
                  getEditorState &&
                  subject.hasSelectedText &&
                  command.section !== COMMAND_SECTIONS.WRITE
                ) && (
                  <div className="gutter-right-1">
                    <Button
                      className="btn-primary"
                      onClick={() => handleOnAcceptCommand()}
                      bsSize={size}
                    >
                      Replace selection
                    </Button>
                  </div>
                )}
                {!!getEditorState && (
                  <div className="gutter-right-1">
                    <Button
                      className="btn-primary"
                      onClick={() => handleOnAcceptCommand(true)}
                      bsSize={size}
                      data-testid="command-result-button-insert"
                    >
                      Insert {!!subject.hasSelectedText && 'below'}
                    </Button>
                  </div>
                )}
              </div>
              <div className="d-flex flex-align-center filter-transparent">
                <div>
                  <ConditionalWrapper
                    condition={isOverMonthlyLimit}
                    wrapper={(children) => (
                      <OverlayTrigger
                        key="command-submit-overlay"
                        placement="top"
                        overlay={
                          <Tooltip id="command-submit-tooltip">
                            The monthly limit of {maxMonthlyUsage} has been reached. You can upgrade
                            to AI Power Mode Pro anytime in your billing center.
                          </Tooltip>
                        }
                      >
                        <PointerEventsWrapper>{children}</PointerEventsWrapper>
                      </OverlayTrigger>
                    )}
                  >
                    {!command.excludeContinue && (
                      <Button
                        onClick={handleOnContinueCommand}
                        bsStyle={null}
                        className="btn-chromeless btn-small"
                        disabled={isOverMonthlyLimit}
                        data-testid="command-result-button-continue-generating"
                      >
                        Continue generating
                      </Button>
                    )}
                    <Button
                      onClick={handleOnRetryCommand}
                      bsStyle={null}
                      className="btn-chromeless btn-small"
                      disabled={isOverMonthlyLimit}
                    >
                      Try again
                    </Button>
                  </ConditionalWrapper>
                </div>
                <div>
                  <Button onClick={discardCommand} bsClass="btn btn-chromeless btn-small">
                    ESC Discard
                  </Button>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

CommandResult.propTypes = propTypes;
CommandResult.defaultProps = defaultProps;
export default CommandResult;

import React, { createContext, useEffect, useMemo, useState } from 'react';
import type { JSX, MutableRefObject } from 'react';
import debounce from '@/storychief/utils/debounce';
import { useEmitEventEffect } from '@/storychief/hooks';
import StoryChief from '@/storychief';
import getPusherListener from '@/pusher/utils/getPusherListener';
import { EDITOR_COLLABORATION_EVENT, EDITOR_COLLABORATION_WHISPER } from '../constants/Constants';
import getEditorCollaborationStorage from '../utils/getEditorCollaborationStorage';
import setEditorCollaborationStorage from '../utils/setEditorCollaborationStorage';
import { User } from '@/types/user';

type BlockedBlockType = {
  block: string;
  user: User;
};
type EditorCollaborationContextType = {
  portalTarget: MutableRefObject<any> | null;
  setPortalTarget: React.Dispatch<React.SetStateAction<MutableRefObject<any> | null>>;
  contentJSON: string;
  setContentJSON: (newValue: string) => void;
  modelType: string | null;
  modelId: string | number;
  blockedBlocks: BlockedBlockType[];
  setBlockedBlocks: React.Dispatch<React.SetStateAction<BlockedBlockType[]>>;
  blockTarget: string | null;
  setBlockTarget: React.Dispatch<React.SetStateAction<string | null>>;
  loading: boolean;
};

export const EditorCollaborationContext = createContext<EditorCollaborationContextType>(undefined);

export function EditorCollaborationContextProvider({ children, modelType, modelId }): JSX.Element {
  // Hooks
  const debouncedSaveBlockInStorage = debounce(saveBlockInStorage, 1000);

  // States
  const [portalTarget, setPortalTarget] = useState(null);
  const [contentJSON, setContentJSON] = useState('');
  const [blockedBlocks, setBlockedBlocks] = useState([]);
  const [loading, setLoading] = useState(false);
  const [blockTarget, setBlockTarget] = useState(null);

  // Variables
  const listener = getPusherListener(modelType, modelId);
  const values = {
    portalTarget,
    setPortalTarget,
    contentJSON,
    setContentJSON: handleSetContentJSON,
    modelType,
    modelId,
    blockedBlocks: getBlockedBlocksOfOtherUsers(),
    setBlockedBlocks,
    blockTarget,
    setBlockTarget,
    loading,
  };

  // Memo
  const providerValue = useMemo(() => values, [values]);

  // Functions
  function handleSetContentJSON(newValue) {
    setContentJSON(newValue);
    debouncedSaveBlockInStorage(newValue);
  }

  function getPrevStateBlockedBlocks(newData, prevState) {
    return prevState.filter((blockedBlock) => {
      if (newData.user) {
        return blockedBlock.user?.id !== newData.user?.id;
      }
      return blockedBlock.block !== newData.block;
    });
  }

  useEmitEventEffect((data) => {
    setBlockedBlocks((prevState) => [...getPrevStateBlockedBlocks(data, prevState), data]);

    listener.whisper(EDITOR_COLLABORATION_WHISPER.startedEditingBlock, data);
  }, EDITOR_COLLABORATION_EVENT.emitStartedEditingBlock);

  useEmitEventEffect((data) => {
    setBlockedBlocks((prevState) => [...getPrevStateBlockedBlocks(data, prevState)]);

    listener.whisper(EDITOR_COLLABORATION_WHISPER.finishedEditingBlock, data);
  }, EDITOR_COLLABORATION_EVENT.emitFinishedEditingBlock);

  useEmitEventEffect(() => {
    listener.whisper(EDITOR_COLLABORATION_WHISPER.shareBlockedBlocks, blockedBlocks);
  }, EDITOR_COLLABORATION_EVENT.emitShareBlockedBlocks);

  useEffect(() => {
    setLoading(true);

    listener
      .listenForWhisper(EDITOR_COLLABORATION_WHISPER.startedEditingBlock, (data) => {
        setBlockedBlocks((prevState) => [...getPrevStateBlockedBlocks(data, prevState), data]);
      })
      .listenForWhisper(EDITOR_COLLABORATION_WHISPER.finishedEditingBlock, (data) => {
        setBlockedBlocks((prevState) => [...getPrevStateBlockedBlocks(data, prevState)]);
      })
      .listenForWhisper(EDITOR_COLLABORATION_WHISPER.shareBlockedBlocks, (data) => {
        setBlockedBlocks(data);
        setLoading(false);
      });

    return () => {
      listener.stopListeningForWhisper(EDITOR_COLLABORATION_WHISPER.startedEditingBlock);
      listener.stopListeningForWhisper(EDITOR_COLLABORATION_WHISPER.finishedEditingBlock);
      listener.stopListeningForWhisper(EDITOR_COLLABORATION_WHISPER.shareBlockedBlocks);
    };
  }, []);

  function saveBlockInStorage(value) {
    if (value) {
      const existing = getEditorCollaborationStorage(modelType);

      let blocks = {};

      if (existing) {
        blocks = JSON.parse(existing);
      }

      if (!blocks[modelId]) {
        blocks[modelId] = {};
      }

      blocks[modelId][blockTarget] = value;

      setEditorCollaborationStorage(modelType, JSON.stringify(blocks));
    }
  }

  function getBlockedBlocksOfOtherUsers() {
    return blockedBlocks.filter((b) => b.user.id !== StoryChief.user.id);
  }

  // Render
  return (
    <EditorCollaborationContext.Provider value={providerValue}>
      {children}
    </EditorCollaborationContext.Provider>
  );
}

import request from 'superagent';
import jsonp from 'superagent-jsonp';

export default function (url) {
  return new Promise((resolve, reject) => {
    request
      .get('https://publish.x.com/oembed')
      .query({ url })
      .use(
        jsonp({
          timeout: 3000,
          callbackName: 'callback',
        }),
      )
      .end((err, res) => {
        if (err) {
          reject(err);
        } else {
          const data = res.body;
          const regExp = /https?:\/\/(twitter|x).com\/.{1,20}\/status\/([0-9]*)/; // eslint-disable-line no-useless-escape, max-len
          const match = data.url.match(regExp);
          if (match) {
            data.tweet_id = match[2];
            resolve(data);
          } else {
            reject();
          }
        }
      });
  });
}

import { EditorBlock } from 'draft-js';
import DefaultBlockComponent from '@/editor/components/blocks/DefaultBlockComponent';
import getPluginsByType from '../../utils/getPluginsByType';
import MergeConflictBlockComponent from '@/editor/components/blocks/MergeConflictBlockComponent';
import StoryChief from '@/storychief';
import getEditorCollaborationStorage from '@/editor/utils/getEditorCollaborationStorage';
import setEditorCollaborationStorage from '@/editor/utils/setEditorCollaborationStorage';

export default (
    setEditorState,
    getEditorState,
    getModelId,
    setReadOnly,
    setLink,
    editorEnabled,
    plugins,
    blockedBlocks,
    modelType,
    modelId,
  ) =>
  (contentBlock) => {
    // Variables
    const savedBlocks = getEditorCollaborationStorage(modelType);

    // Functions
    function getIsBlockBlocked() {
      return blockedBlocks?.some(
        (blockedBlock) =>
          blockedBlock.block === contentBlock.getKey() &&
          blockedBlock.user.id !== StoryChief.user.id,
      );
    }

    function getIsBlockConflicted() {
      if (savedBlocks) {
        const parsed = JSON.parse(savedBlocks)[modelId];

        if (parsed && Object.keys(parsed).includes(contentBlock.getKey())) {
          const conflictedBlock = getConflictedBlock();
          const conflictedBlockText = conflictedBlock.blocks.map((b) => b.text).join(' ');

          return conflictedBlockText !== contentBlock.getText();
        }
      }

      deleteBlockFromLocalStorage();

      return false;
    }

    function getConflictedBlock() {
      if (savedBlocks) {
        const parsed = JSON.parse(savedBlocks)[modelId];

        if (parsed) {
          return parsed[contentBlock.getKey()];
        }
      }

      return null;
    }

    function deleteBlockFromLocalStorage() {
      if (savedBlocks) {
        const allBlocks = JSON.parse(savedBlocks);

        if (allBlocks[modelId]) {
          const modelBlocks = allBlocks[modelId];

          delete modelBlocks[contentBlock.getKey()];

          if (Object.keys(modelBlocks).length === 0) {
            delete allBlocks[modelId];
          }

          setEditorCollaborationStorage(modelType, JSON.stringify(allBlocks));
        }
      }
    }

    // Return
    if (contentBlock.getType() !== 'atomic') {
      if (editorEnabled) {
        if (!getIsBlockBlocked()) {
          if (getIsBlockConflicted()) {
            return {
              component: MergeConflictBlockComponent,
              editable: false,
              props: {
                conflictedBlock: getConflictedBlock(),
              },
            };
          }

          return null;
        }
      }

      return {
        component: DefaultBlockComponent,
        editable: false,
        props: {
          childrenRenderer: (blockProps) => <EditorBlock {...blockProps} block={contentBlock} />,
        },
      };
    }
    const blockData = contentBlock.getData().toObject();

    const { type } = blockData;

    if (typeof type !== 'undefined') {
      const pluginTypes = getPluginsByType(plugins);
      if (!pluginTypes[type]) {
        return null;
      }
      // set selected state
      const editorState = getEditorState();
      const selection = editorState.getSelection();
      const content = editorState.getCurrentContent();
      const startKey = selection.getStartKey();
      const selectedBlock = content.getBlockForKey(startKey);
      let isSelected = false;
      if (selectedBlock && selectedBlock.getKey() === contentBlock.getKey() && editorEnabled) {
        isSelected = true;
      }

      if (type === 'image' && !blockData.src && !editorEnabled) {
        return null;
      }

      return {
        component: pluginTypes[type].blockComponent,
        editable: editorEnabled && pluginTypes[type].editable,
        props: {
          isSelected,
          setEditorState,
          getEditorState,
          getModelId,
          setReadOnly,
          setLink,
          editorEnabled,
        },
      };
    }

    return null;
  };

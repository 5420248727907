import { Component } from 'react';
import PropTypes from 'prop-types';
import { Picker } from 'emoji-mart';
import classNames from 'classnames';
import urlRegex from 'url-regex';
import twitterText from 'twitter-text';
import { Collapse, OverlayTrigger, Popover, Tooltip } from 'react-bootstrap';
import {
  CompositeDecorator,
  ContentState,
  convertFromRaw,
  convertToRaw,
  Editor,
  EditorState,
  Modifier,
  RichUtils,
  getDefaultKeyBinding,
} from 'draft-js';
import { debounce } from 'lodash-es';
import { Icon } from '@iconify-icon/react';
import { CHANNEL_TWITTER } from '@/destinations/constants/Constants';
import StoryChief from '@/storychief';
import { getCurrentMention, getCurrentMentionWithSpaces } from '@/editor/utils/getCurrentMention';
import MentionSuggestions from './MentionSuggestions';
import { addMentionToEditorState, addMentionSelectToEditorState } from './Modifiers';
import {
  MentionSpan,
  MentionDisabledSpan,
  OverflowSpan,
  LinkSpan,
  MentionInactiveSpan,
} from './Decorators';
import {
  mentionStrategy,
  mentionSelectStrategy,
  mentionTwitterStrategy,
  mentionInactiveStrategy,
  mentionInactiveWithSpacesStrategy,
} from './Strategies';
import tryParseJson from '../../utils/tryParseJson';
import findWithRegex from '@/editor/utils/findWithRegex';
import getCurrentTextData from '@/editor/utils/getCurrentTextData';
import getDestinationMessageLength from '@/destinations/utils/getDestinationMessageLength';
import AssistantProvider from '@/assistant/components/AssistantProvider';
import getCurrentBlock from '@/editor/utils/getCurrentBlock';
// The dependency cycle is caused by <TextEditor> importing assistant components and those components also importing <TextEditor>.
// The issue is mitigated by disabling the assistant on <TextEditor> inside the assistant components.
// eslint-disable-next-line import/no-cycle
import BlockToolbarAssistant from '@/editor/components/blocks/BlockToolbarAssistant';
import AssistantDropdown from '@/assistant/components/AssistantDropdown';
import { CUSTOM_BLOCK_STYLES } from '@/editor/constants/Constants';

export const propTypes = {
  allowNewLines: PropTypes.bool,
  formControl: PropTypes.bool,
  formControlAutoHeight: PropTypes.bool,
  editorMaxSize: PropTypes.oneOf(['sm', 'md']),
  formControlId: PropTypes.string,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  placeholder: PropTypes.string,
  limit: PropTypes.number,
  limitType: PropTypes.oneOf(['character', 'size']),
  limitSizeSelector: PropTypes.string,
  showLimitIndicator: PropTypes.bool,
  limitInfo: PropTypes.string,
  readOnly: PropTypes.bool,
  isLocked: PropTypes.bool,
  emojiPicker: PropTypes.bool,
  emojiPickerPlacement: PropTypes.string,
  mentionPicker: PropTypes.bool,
  mentionSearchResults: PropTypes.arrayOf(PropTypes.shape({})),
  mentionSearchPending: PropTypes.bool,
  mentionsAllowSpaces: PropTypes.bool,
  mentionMinLengthSearch: PropTypes.number,
  mentionDisabledTooltip: PropTypes.string,
  onMentionSearch: PropTypes.func,
  destination: PropTypes.shape({
    channel_type: PropTypes.number.isRequired,
  }),
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onReturn: PropTypes.func,
  onContentChange: PropTypes.func.isRequired /** saves string or JSON-string. */,
  isDraftJs: PropTypes.bool,
  spellCheck: PropTypes.bool,
  onUrlFind: PropTypes.func,
  focusOnMount: PropTypes.bool,
  // eslint-disable-next-line react/no-unused-prop-types
  focusOnReadOnlyChange: PropTypes.bool, // Used in componentWillReceiveProps.
  debounceContentChange: PropTypes.bool,
  mentionType: PropTypes.string,
  mentionsDisabled: PropTypes.bool,
  assistant: PropTypes.bool,
  assistantSubject: PropTypes.shape({
    type: PropTypes.string,
    language: PropTypes.string,
  }),
  customFooter: PropTypes.node,
  isShowFooter: PropTypes.bool,
  isAnimateFooterToggle: PropTypes.bool,
  actionsPosition: PropTypes.oneOf(['default', 'footer']),
};

export const defaultProps = {
  allowNewLines: false,
  assistant: !!StoryChief?.user?.role,
  assistantSubject: {},
  content: null,
  debounceContentChange: true,
  destination: null,
  emojiPicker: false,
  emojiPickerPlacement: 'right',
  mentionPicker: false,
  focusOnMount: false,
  focusOnReadOnlyChange: true,
  formControl: false,
  formControlAutoHeight: false,
  editorMaxSize: null,
  formControlId: null,
  isDraftJs: false,
  isLocked: false,
  mentionMinLengthSearch: 0,
  mentionsAllowSpaces: false,
  mentionsDisabled: false,
  mentionSearchPending: false,
  mentionSearchResults: [],
  mentionDisabledTooltip: undefined,
  mentionType: 'DEFAULT',
  onBlur: null,
  onFocus: null,
  onMentionSearch: null,
  onReturn: null,
  onUrlFind: null,
  placeholder: 'Write post',
  readOnly: false,
  spellCheck: false,
  limit: null,
  limitType: 'character',
  limitSizeSelector: null,
  showLimitIndicator: false,
  limitInfo: null,
  customFooter: null,
  isShowFooter: true,
  isAnimateFooterToggle: false,
  actionsPosition: 'default',
};

class TextEditor extends Component {
  constructor(props) {
    super(props);
    this.saveContentState = props.debounceContentChange
      ? debounce(this.saveContentState, 1000)
      : this.saveContentState;
    this.handleReturn = this.handleReturn.bind(this);
    this.handleOnUrlFind = debounce(this.handleOnUrlFind, 200);
    this.state = {
      editorState: this.initEditorState(props),
      hasFocus: false,
      isMentioning: false,
      mentionSearchQuery: '',
      sizeCount: 0,
    };
  }

  componentDidMount() {
    const { limitSizeSelector } = this.props;

    if (this.props.focusOnMount) {
      setTimeout(() => {
        this.focus();
      }, 0);
    }
    this.setMinHeightEditorContainer();

    if (limitSizeSelector) {
      this.resizeObserver = new ResizeObserver(() => {
        this.setSizeCount();
      });
      this.resizeObserver.observe(document.querySelector(`.${limitSizeSelector}`));
    }
  }

  componentWillUnmount() {
    if (this.resizeObserver) {
      this.resizeObserver.unobserve(document.querySelector(`.${this.props.limitSizeSelector}`));
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.readOnly || this.props.readOnly !== nextProps.readOnly) {
      let newEditorState = this.initEditorState(nextProps);
      if (!nextProps.readOnly && nextProps.focusOnReadOnlyChange) {
        newEditorState = EditorState.moveFocusToEnd(newEditorState);
      }
      this.setState({
        editorState: newEditorState,
      });
    }
  }

  componentDidUpdate() {
    this.setMinHeightEditorContainer();
  }

  getEditorHeight = () => this.editorNode && this.editorNode.editorContainer.clientHeight;

  getEditorState = () => this.state.editorState;

  getEditorNode = () => this.editorNode;

  onFocus = () => {
    if (this.props.onFocus) this.props.onFocus();
    this.setState({ hasFocus: true });
  };

  onBlur = () => {
    if (this.props.onBlur) this.props.onBlur();
    this.disableMentions();
    this.setState({ hasFocus: false });
  };

  focus = () => {
    if (this.editorNode) {
      this.editorNode.focus();
    }
  };

  getMentionDefaultStrategy = () => {
    if (this.props.destination.channel_type === CHANNEL_TWITTER) {
      return mentionTwitterStrategy;
    }

    return mentionStrategy;
  };

  getMentionInactiveStrategy = () => {
    if (this.props.mentionsAllowSpaces) {
      return mentionInactiveWithSpacesStrategy;
    }

    return mentionInactiveStrategy;
  };

  onChange = (editorState) => {
    const cursorPos = editorState.getSelection().getAnchorOffset();

    const contentState = editorState.getCurrentContent();
    const [index, text] = getCurrentTextData(editorState);

    if (contentState !== this.state.editorState.getCurrentContent()) {
      // content has changed, save it
      this.saveContentState(contentState);
    }
    this.setState({ editorState }, () => {
      const currentMention = this.props.mentionsAllowSpaces
        ? getCurrentMentionWithSpaces({
            index,
            text,
            channelType: this.props.destination?.channel_type,
          })
        : getCurrentMention({ index, text, channelType: this.props.destination?.channel_type });

      if (!currentMention || !this.state.hasFocus || this.props.mentionsDisabled) {
        this.disableMentions();
        return;
      }

      if (this.hasMentionEnabled()) {
        const { onMentionSearch } = this.props;

        const queryStartPos = currentMention.text.lastIndexOf('@') + 1;
        const _mentionSearchQuery = text.slice(currentMention.start + queryStartPos, cursorPos);
        let isMentioning = true;
        // For Twitter, we set isMentioning to false if there is a space in the _mentionSearchQuery
        if (this.props.destination?.channel_type === CHANNEL_TWITTER) {
          isMentioning = !/\s/.test(_mentionSearchQuery);
        }
        this.setState({ isMentioning }, () => {
          if (onMentionSearch) {
            this.setState({ mentionSearchQuery: _mentionSearchQuery });
            onMentionSearch(_mentionSearchQuery);
          }
        });
      } else {
        this.setState({ isMentioning: true });
      }
    });
  };

  getCharacterCount = () => {
    const { editorState } = this.state;
    const { limit } = this.props;
    const text = editorState.getCurrentContent().getPlainText('');
    const textLength = getDestinationMessageLength(text, this.props.destination?.channel_type);
    return limit ? limit - textLength : textLength;
  };

  setSizeCount = () => {
    const { limitSizeSelector, limit } = this.props;
    const node = document.querySelector(`.${limitSizeSelector}`);

    if (node) {
      const nodeWidth = Math.floor(node.getBoundingClientRect().width);

      this.setState({ sizeCount: limit ? limit - nodeWidth : nodeWidth });
    } else {
      this.setState({ sizeCount: 0 });
    }
  };

  getLimitCount = () => {
    const { limitType } = this.props;

    switch (limitType) {
      case 'character':
        return this.getCharacterCount();
      case 'size':
        return this.state.sizeCount;
      default:
        return 0;
    }
  };

  getParsedContent = (content) => {
    let parsedContent = '';
    if (content) {
      if (this.props.isDraftJs) {
        parsedContent = tryParseJson(content);
        parsedContent = parsedContent.parsed.blocks[0].text;
      } else {
        parsedContent = content;
      }
    }
    return parsedContent;
  };

  linkStrategy = (contentBlock, callback) => {
    findWithRegex(urlRegex({ strict: false }), contentBlock, (start, end, match) => {
      callback(start, end);
      // Ignore emails as the regex returns both URLs and email addresses.
      if (match && !match.includes('@')) {
        this.handleOnUrlFind(match);
      }
    });
  };

  overflowStrategy = (contentBlock, callback) => {
    if (this.props.limitInfo) return;
    const { limit } = this.props;
    if (limit) {
      const text = contentBlock.getText();
      const textLength = getDestinationMessageLength(text, this.props.destination?.channel_type);

      if (textLength > limit) {
        if (this.props.destination?.channel_type === CHANNEL_TWITTER) {
          const tweet = twitterText.parseTweet(text);
          callback(tweet.validRangeEnd + 1, text.length);
        } else {
          callback(limit, text.length);
        }
      }
    }
  };

  initEditorState = (props) => {
    let content = '';
    let newContentState = null;
    if (props.isDraftJs) {
      newContentState = props.content ? convertFromRaw(JSON.parse(props.content)) : null;
    } else {
      content = {
        entityMap: {},
        blocks: [
          {
            key: '59kd9',
            text: props.content || '',
            type: 'unstyled',
            depth: 0,
            inlineStyleRanges: [],
            entityRanges: [],
            data: {},
          },
        ],
      };
      newContentState = convertFromRaw(content);
    }

    const overflowDecorator = {
      strategy: this.overflowStrategy,
      component: OverflowSpan,
    };

    const compositeDecorator = [overflowDecorator];

    if (this.props.onMentionSearch) {
      // Active mentions
      const mentionDecorator = {
        strategy:
          this.props.mentionType === 'SELECT'
            ? mentionSelectStrategy
            : this.getMentionDefaultStrategy(),
        component: MentionSpan,
      };
      compositeDecorator.push(mentionDecorator);

      // Inactive mentions
      const mentionInactiveDecorator = {
        strategy: this.getMentionInactiveStrategy(),
        component: MentionInactiveSpan,
      };
      compositeDecorator.push(mentionInactiveDecorator);
    } else {
      const mentionDecorator = {
        strategy: mentionStrategy,
        component: MentionDisabledSpan,
      };
      compositeDecorator.push(mentionDecorator);
    }

    if (this.props.onUrlFind) {
      const linkDecorator = {
        strategy: this.linkStrategy,
        component: LinkSpan,
      };
      compositeDecorator.push(linkDecorator);
    }

    const decorators = new CompositeDecorator(compositeDecorator);
    if (newContentState) {
      return EditorState.createWithContent(newContentState, decorators);
    }
    return EditorState.createEmpty(decorators);
  };

  setMinHeightEditorContainer = () => {
    if (this.props.formControlAutoHeight && !this.props.isLocked) {
      const placeholder = this.editorWrapperNode.getElementsByClassName(
        'public-DraftEditorPlaceholder-inner',
      );
      if (placeholder.length) {
        const minHeight = `${placeholder[0].offsetHeight}px`;
        const editorContentNode = this.editorNode.editorContainer.getElementsByClassName(
          'public-DraftEditor-content',
        );
        if (editorContentNode.length) {
          editorContentNode[0].style.minHeight = minHeight;
        }
        this.editorNode.editorContainer.style.minHeight = minHeight;
      }
    }
  };

  onEscape = (e) => {
    e.preventDefault();
    this.disableMentions();
    return 'custom-escape';
  };

  handlePastedText = (text) => {
    if (text) {
      const { editorState } = this.state;
      const escapedText = text.replace(/\u{FEFF}/gu, ''); // Remove non-printable character that is causing formatting issues when posting to Instagram

      // in order to prevent overflow decorator on pasted text, we must have only one block
      // thus, inserting the text with a modifier
      if (this.props.allowNewLines) {
        const newContent = Modifier.replaceText(
          editorState.getCurrentContent(),
          editorState.getSelection(),
          escapedText,
        );
        this.onChange(EditorState.push(this.state.editorState, newContent, 'insert-characters'));
      } else if (escapedText) {
        // otherwise we can use the create new block logic below
        const NEWLINE_REGEX = /\n/g;
        const textNoNewline = escapedText.replace(NEWLINE_REGEX, ' ');
        const blockMap = ContentState.createFromText(textNoNewline.trim()).blockMap;
        const newState = Modifier.replaceWithFragment(
          editorState.getCurrentContent(),
          editorState.getSelection(),
          blockMap,
        );
        this.onChange(EditorState.push(editorState, newState, 'insert-fragment'));
      }
    }

    return true;
  };

  handleReturn = (e) => {
    if (this.state.isMentioning) {
      const handleEnter = this.suggestionsNode.handleEnter();
      // Only return when handled otherwise a new block will be added
      if (handleEnter === 'handled') {
        return handleEnter;
      }
    }
    if (this.props.allowNewLines) {
      if (e.shiftKey || (!e.shiftKey && !this.props.onReturn)) {
        this.onChange(RichUtils.insertSoftNewline(this.state.editorState));
      }
    }
    if (!e.shiftKey && this.props.onReturn) {
      this.props.onReturn();
    }
    return 'handled';
  };

  handleOnMentionSuggestionClick = (mention) => {
    const { editorState } = this.state;
    const newEditorState =
      this.props.mentionType === 'SELECT'
        ? addMentionSelectToEditorState(editorState, mention)
        : addMentionToEditorState(editorState, mention, this.props.destination?.channel_type);
    this.disableMentions();
    this.onChange(newEditorState);
  };

  handleOnArrowKeyClick = (e, direction) => {
    e.preventDefault();
    this.suggestionsNode.handleOnArrowKeyClick(direction);
    return `custom-arrow-${direction}`;
  };

  disableMentions = () => {
    this.setState((prevState) => ({
      ...prevState,
      isMentioning: false,
      mentionSearchQuery: '',
    }));
  };

  hasMentionEnabled = () => !!this.props.onMentionSearch;

  addEmoji = (emoji) => {
    const { editorState } = this.state;
    const currentContent = editorState.getCurrentContent();
    const selection = editorState.getSelection();
    let textWithEntity = null;
    if (selection.isCollapsed()) {
      textWithEntity = Modifier.insertText(currentContent, selection, emoji.native);
    } else {
      textWithEntity = Modifier.replaceText(currentContent, selection, emoji.native);
    }
    const newEditorState = EditorState.push(editorState, textWithEntity, 'insert-characters');
    this.setState(
      {
        editorState: newEditorState,
      },
      () => {
        document.body.click();
        this.focus();
      },
    );
    const contentState = newEditorState.getCurrentContent();
    this.saveContentState(contentState);
  };

  saveContentState(contentState) {
    // saving string or JSON-string depending on onMentionSearch prop
    const { onContentChange, isDraftJs } = this.props;
    let content;
    if (!contentState.hasText()) {
      onContentChange(isDraftJs ? null : '');
    } else if (!isDraftJs) {
      const block = contentState.getBlockForKey('59kd9');
      content = block.getText();
      onContentChange(content);
    } else {
      onContentChange(JSON.stringify(convertToRaw(contentState)));
    }
  }

  handleOnUrlFind(url) {
    this.props.onUrlFind(url);
  }

  handleKeyBindings = (e) => {
    if (this.state.isMentioning) {
      if (e.key === 'ArrowUp') {
        return this.handleOnArrowKeyClick(e, 'up');
      }

      if (e.key === 'ArrowDown') {
        return this.handleOnArrowKeyClick(e, 'down');
      }

      if (e.key === 'Escape') {
        return this.onEscape(e);
      }
    }

    return getDefaultKeyBinding(e);
  };

  handleAddMention = () => {
    const editorState = this.state.editorState;
    const currentContent = editorState.getCurrentContent();
    const selection = editorState.getSelection();
    let textWithEntity = null;
    if (selection.isCollapsed()) {
      textWithEntity = Modifier.insertText(currentContent, selection, ' @');
    } else {
      textWithEntity = Modifier.replaceText(currentContent, selection, ' @');
    }
    const newEditorState = EditorState.push(editorState, textWithEntity, 'insert-characters');
    this.setState(
      {
        editorState: newEditorState,
      },
      () => {
        this.focus();
      },
    );
    const contentState = newEditorState.getCurrentContent();
    this.saveContentState(contentState);
  };

  preventEmojiLosingFocus = (e) => {
    // Prevent the emoji search field from losing focus.
    e.stopPropagation();
  };

  renderActions() {
    if (this.props.readOnly) {
      return null;
    }

    const {
      assistant,
      assistantSubject,
      emojiPicker,
      emojiPickerPlacement,
      mentionPicker,
      readOnly,
    } = this.props;
    const popover = (
      <Popover
        className="popover--emoji"
        id="popover-emoji"
        title=""
        onClick={this.preventEmojiLosingFocus}
      >
        <Picker showPreview={false} onClick={this.addEmoji} title="" />
      </Popover>
    );
    const currentBlock = getCurrentBlock(this.state.editorState);

    return (
      <div className="text-editor--actions">
        {emojiPicker && (
          <OverlayTrigger
            trigger="click"
            container={this}
            rootClose
            placement={emojiPickerPlacement}
            overlay={popover}
          >
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip id="tooltip-emoji">Insert an emoji</Tooltip>}
            >
              <button type="button" className="btn btn-chromeless" aria-label="Insert an emoji">
                <Icon icon="fa:smile-o" width="14" height="14" inline className="mr-1" />
              </button>
            </OverlayTrigger>
          </OverlayTrigger>
        )}
        {mentionPicker && (
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip id="tooltip-mention">Mention someone</Tooltip>}
          >
            <button
              onClick={this.handleAddMention}
              type="button"
              className="btn btn-chromeless"
              aria-label="Mention someone"
              data-testid="mention-someone"
            >
              <Icon icon="fa:at" inline className="mr-1" width="14" height="14" />
            </button>
          </OverlayTrigger>
        )}

        {assistant && (
          <AssistantProvider
            getEditorState={this.getEditorState}
            setEditorState={this.onChange}
            subject={{
              ...assistantSubject,
              component: 'TextEditor',
              hasSelectedText: !this.state.editorState.getSelection().isCollapsed(),
              isEmpty: !this.state.editorState.getCurrentContent().hasText(),
            }}
          >
            <AssistantDropdown
              includeMenu={false}
              disabled={readOnly}
              getEditorState={this.getEditorState}
              setEditorState={this.onChange}
              className="btn btn-chromeless"
              pullRight
              hasSelectedText={!this.state.editorState.getSelection().isCollapsed()}
            />
            {currentBlock && (
              <BlockToolbarAssistant
                getEditorNode={this.getEditorNode}
                currentBlock={currentBlock}
                size="sm"
              />
            )}
          </AssistantProvider>
        )}
      </div>
    );
  }

  renderFooter = () => {
    const { actionsPosition, customFooter, limitType, showLimitIndicator, limitInfo } = this.props;

    return (
      <div>
        <div className="text-editor-footer hidden-print">
          <div>{actionsPosition === 'footer' && this.renderActions()}</div>

          <div>
            <div className="text-right">
              {customFooter}
              {showLimitIndicator && (
                <>
                  <small className="text-muted">{`${this.getLimitCount()} ${
                    limitType === 'character' ? 'characters' : 'pixels'
                  }`}</small>
                  {this.getLimitCount() < 0 && limitInfo && (
                    <OverlayTrigger
                      placement="bottom"
                      overlay={<Tooltip id="help-description">{limitInfo}</Tooltip>}
                    >
                      <Icon
                        icon="fa:question-circle"
                        inline
                        className="text-muted ml-1"
                        width="14"
                      />
                    </OverlayTrigger>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    const {
      showLimitIndicator,
      content,
      destination,
      formControl,
      formControlAutoHeight,
      editorMaxSize,
      formControlId,
      isLocked,
      mentionMinLengthSearch,
      mentionSearchPending,
      mentionSearchResults,
      mentionDisabledTooltip,
      placeholder,
      readOnly,
      spellCheck,
      actionsPosition,
      customFooter,
      isShowFooter,
      isAnimateFooterToggle,
    } = this.props;

    const { hasFocus, isMentioning, mentionSearchQuery } = this.state;

    const formControlClassName = classNames('', {
      'form-control': formControl,
      'form-control-auto-height': formControlAutoHeight,
      'form-control-max-height': !!editorMaxSize,
      [`editor--max--${editorMaxSize}`]: !!editorMaxSize,
      focus: hasFocus,
      disabled: readOnly,
      locked: isLocked,
    });

    return (
      <div
        ref={(node) => {
          this.editorWrapperNode = node;
        }}
        id={formControlId}
        className={`${formControlClassName} text-editor position-relative`}
      >
        {isLocked ? (
          <div className="row">
            <div className="col-xs-12 text-muted text-word-break">
              <Icon icon="fa:lock" width="12" height="12" />
              <span className="gutter-1">{this.getParsedContent(content)}</span>
            </div>
          </div>
        ) : (
          <>
            <Editor
              ref={(node) => {
                this.editorNode = node;
              }}
              readOnly={readOnly}
              placeholder={placeholder}
              editorState={this.state.editorState}
              getEditorState={this.getEditorState}
              onChange={this.onChange}
              onFocus={this.onFocus}
              onBlur={this.onBlur}
              handlePastedText={this.handlePastedText}
              handleReturn={this.handleReturn}
              stripPastedStyles
              tabIndex="0"
              keyBindingFn={this.handleKeyBindings}
              customStyleMap={CUSTOM_BLOCK_STYLES}
              spellCheck={spellCheck}
            />
            <MentionSuggestions
              ref={(node) => {
                this.suggestionsNode = node;
              }}
              mentions={mentionSearchResults}
              mentionSearchPending={mentionSearchPending}
              mentionMinLengthSearch={mentionMinLengthSearch}
              mentionSearchQuery={mentionSearchQuery}
              mentionDisabledTooltip={mentionDisabledTooltip}
              destination={destination}
              show={isMentioning}
              enabled={this.hasMentionEnabled()}
              onMentionSuggestionClick={this.handleOnMentionSuggestionClick}
            />
            {actionsPosition === 'default' && this.renderActions()}
            {(!!customFooter || showLimitIndicator) && isAnimateFooterToggle && (
              <Collapse in={isShowFooter} className="w-100">
                {this.renderFooter()}
              </Collapse>
            )}
            {(!!customFooter || showLimitIndicator) && !isAnimateFooterToggle && isShowFooter && (
              <div className="w-100">{this.renderFooter()}</div>
            )}
          </>
        )}
      </div>
    );
  }
}

TextEditor.propTypes = propTypes;
TextEditor.defaultProps = defaultProps;

export default TextEditor;

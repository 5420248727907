import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';
import CopyToClipboard from 'react-copy-to-clipboard';
import { convertFromRaw, convertToRaw, EditorState } from 'draft-js';
import { markdownToDraft } from 'markdown-draft-js';
import { Icon } from '@iconify-icon/react';
import useAssistant from '@/assistant/hooks/useAssistant';
import getDefaultDecorator from '@/editor/decorators/getDefaultDecorator';
import markDownToDraftWithDynamicContent from '@/assistant/utils/markDownToDraftWithDynamicContent';
import StoryChief from '@/storychief';
import CommandResultTranscriptItemContent from './CommandResultTranscriptItemContent';
import appendUrlProtocol from '@/storychief/utils/appendUrlProtocol';
import { CREATE_POSTSET_EDIT_ATTACHMENT_LINK_MUTATION } from '@/postsets/components/sidebars/PostsetEditAttachmentLink';
import useSidebars from '@/sidebars/hooks/useSidebars';
import DateRangePicker from '@/storychief/components/DateRangePicker';
import { formatDate, getISODate } from '@/date';
import { useCreatePostsetMutation } from '@/postsets/hooks';
import pushHistoryAddOverlayPostset from '@/postsets/utils/pushHistoryAddOverlayPostset';
import ConditionalWrapper from '@/storychief/components/ConditionalWrapper';
import PointerEventsWrapper from '@/storychief/components/PointerEventsWrapper';
import subtractTitleFromEditorState from '@/editor/utils/subtractTitleFromEditorState';
import analyticsTrack from '@/storychief/utils/analyticsTrack';
import { COMMAND_IDENTIFIERS } from '@/assistant/constants/Constants';
import getDateObjectWithTimezone from '@/date/getDateObjectWithTimezone';

const propTypes = {
  size: PropTypes.oneOf(['sm', 'lg']),
  insidePopover: PropTypes.bool,
  item: PropTypes.shape({
    content: PropTypes.string,
    role: PropTypes.string,
    id: PropTypes.string,
  }).isRequired,
  loading: PropTypes.bool,
  lastItem: PropTypes.bool,
  onRetry: PropTypes.func,
  onContinue: PropTypes.func,
  selectedEditorStates: PropTypes.arrayOf(PropTypes.shape({})),
  onSelectedEditorStatesChange: PropTypes.func,
};

const defaultProps = {
  insidePopover: false,
  loading: false,
  lastItem: false,
  onRetry: null,
  onContinue: null,
  size: null,
  selectedEditorStates: [],
  onSelectedEditorStatesChange: () => {},
};

const fragments = {
  story: gql`
    fragment CommandResultStory on Story {
      __typename
      id
      title
      content
      edit_url
    }
  `,
};

const CREATE_STORY_MUTATION = gql`
  mutation CommandResultTranscriptItemCreateStoryMutation($input: CreateStoryInput!) {
    createStory(input: $input) {
      ...CommandResultStory
    }
  }
  ${fragments.story}
`;

const ATTACH_POSTSET_STORY_MUTATION = gql`
  mutation CommandResultTranscriptItemAttachPostsetStory($id: ID!, $story_id: ID!) {
    attachPostsetStory(id: $id, story_id: $story_id) {
      id
    }
  }
`;

const ATTACH_POSTSET_LINK_MUTATION = gql`
  mutation CommandResultTranscriptItemAttachPostsetLink($id: ID!, $link_id: ID!) {
    attachPostsetLink(id: $id, link_id: $link_id) {
      id
    }
  }
`;

function CommandResultTranscriptItem({
  item,
  insidePopover,
  loading,
  lastItem,
  onRetry,
  onContinue,
  size,
  selectedEditorStates,
  onSelectedEditorStatesChange,
}) {
  const {
    getEditorState,
    cancelCommand,
    subject,
    isOverMonthlyLimit,
    maxMonthlyUsage,
    command,
    assetSource,
    assetUrl,
    assetContentPieces,
  } = useAssistant();
  const history = useHistory();
  const sidebars = useSidebars();
  const [saving, setSaving] = useState();

  const isContentTypeChat = subject.type === 'AssistantSidebarContentTypesChat';
  const [editorState, setEditorState] = useState(
    EditorState.createEmpty(getDefaultDecorator({ editorEnabled: false })),
  );

  const [editorStates, setEditorStates] = useState();

  const createdEditorStates = useMemo(
    () => editorStates?.filter((editorStateItem) => !!editorStateItem.entityId) || [],
    [editorStates],
  );

  const editorStateHasHeader = useMemo(() => {
    if (editorState && !loading) {
      return editorState
        .getCurrentContent()
        .getBlocksAsArray()
        .some((block) => block.getType().startsWith('header-'));
    }
    return false;
  }, [editorState, loading]);

  function stripCheckboxSyntax(text) {
    return text.replace(/- \[\s*\]/g, '- ');
  }

  useEffect(() => {
    if (item.content) {
      const isSocialPostCommand = command.identifier === COMMAND_IDENTIFIERS.SOCIAL_POST;
      // The checkbox syntax is supported only on the social post command.
      const content = isSocialPostCommand ? item.content : stripCheckboxSyntax(item.content);

      // On the social post command the user gets a list of social posts they can select and create.
      if (isSocialPostCommand && /- \[\s*\]/.test(item.content)) {
        const items = item.content
          .split(/\n(?=- \[\s*\])/)
          .map((contentItem) => contentItem.trim());
        const _editorStates = items.map((contentItem, index) => {
          const contentState = getEditorState
            ? markDownToDraftWithDynamicContent(
                contentItem.replace(/- \[\s*\]/g, '').trim(),
                getEditorState().getCurrentContent(),
              )
            : convertFromRaw(
                markdownToDraft(contentItem.replace(/- \[\s*\]/g, '').trim(), {
                  remarkableOptions: {
                    html: true,
                  },
                }),
              );
          return {
            id: `${item.id}-${index}`,
            editorState: EditorState.createWithContent(contentState),
            selectable: /- \[\s*\]/.test(contentItem),
            scheduled_at: subject?.scheduled_at || null,
          };
        });
        setEditorStates(_editorStates);
      }

      const contentState = getEditorState
        ? markDownToDraftWithDynamicContent(content, getEditorState().getCurrentContent())
        : convertFromRaw(
            markdownToDraft(content, {
              remarkableOptions: {
                html: true,
              },
            }),
          );

      setEditorState(EditorState.push(editorState, contentState));
    }
  }, [item.content]);

  /* Mutations */
  const [createMutation, { loading: loadingStory }] = useMutation(CREATE_STORY_MUTATION);

  const [createPostsetMutation, { loading: loadingPostset }] = useCreatePostsetMutation(false);

  const [attachPostsetStory] = useMutation(ATTACH_POSTSET_STORY_MUTATION, {
    refetchQueries: ['StoryPostsets'],
  });

  const [attachPostsetLink] = useMutation(ATTACH_POSTSET_LINK_MUTATION);

  const [createLink] = useMutation(CREATE_POSTSET_EDIT_ATTACHMENT_LINK_MUTATION);

  /* Functions */
  async function onCreateStory() {
    analyticsTrack('Assistant Command Saved', {
      command: command.identifier,
      save_type: 'story',
      subject_type: subject?.type || null,
    });
    const { titleEditorState, contentEditorState } = subtractTitleFromEditorState(
      editorState,
      true,
    );

    const contentRaw = convertToRaw(contentEditorState.getCurrentContent());
    let title = '';
    if (titleEditorState) {
      title = titleEditorState.getCurrentContent().getPlainText('\u0001');
    }
    const inputCreateStory = {
      title,
      language: null,
      content: JSON.stringify(contentRaw),
    };

    const {
      data: { createStory },
    } = await createMutation({
      variables: {
        input: inputCreateStory,
      },
    });
    window.open(createStory.edit_url, '_blank');
  }

  async function onCreatePostset() {
    analyticsTrack('Assistant Command Saved', {
      command: command.identifier,
      save_type: 'postset',
      subject_type: subject?.type || null,
    });

    const blocks = editorState.getCurrentContent().getBlockMap();
    let text = '';

    blocks.forEach((block) => {
      const blockText = block.getText();

      if (['unordered-list-item', 'ordered-list-item'].includes(block.getType())) {
        text += `- ${blockText}\n`;
      } else {
        text += `${blockText}\n`;
      }
    });

    const message = JSON.stringify({
      entityMap: {},
      blocks: [
        {
          key: '59kd9',
          text,
          type: 'unstyled',
          depth: 0,
          inlineStyleRanges: [],
          entityRanges: [],
          data: {},
        },
      ],
    });

    const inputCreatePostset = {
      message,
      campaigns: {
        connect: subject?.initialCampaigns?.map(({ id }) => id) || [],
      },
    };

    const {
      data: { createPostset },
    } = await createPostsetMutation({
      variables: {
        input: inputCreatePostset,
      },
    });

    pushHistoryAddOverlayPostset(
      history,
      [createPostset.id],
      subject?.initialCampaigns?.map(({ id }) => id) || [],
    );
    // we need to close all sidebars otherwise the overlay will not be visible
    sidebars.closeAll();
  }

  function handleOnViewSavedPosts() {
    pushHistoryAddOverlayPostset(
      history,
      createdEditorStates.map((editorStateItem) => editorStateItem.entityId),
      subject?.initialCampaigns?.map(({ id }) => id) || [],
    );
    // we need to close all sidebars otherwise the overlay will not be visible
    sidebars.closeAll();
  }

  function handleOnSelectContentEntity(id) {
    if (isSelected(id)) {
      onSelectedEditorStatesChange(selectedEditorStates.filter((s) => s.id !== id));
    } else {
      const selectedEditorStateIds = selectedEditorStates.map((s) => s.id);

      onSelectedEditorStatesChange(
        editorStates.filter((s) => s.id === id || selectedEditorStateIds.includes(s.id)),
      );
    }
  }

  function isSelected(itemId) {
    return selectedEditorStates.some((s) => s.id === itemId);
  }

  function handleOnContentEntityScheduledAtChange(id, scheduled_at) {
    const newEditorStates = editorStates.map((_item) => {
      if (_item.id === id) {
        return {
          ..._item,
          scheduled_at,
        };
      }
      return _item;
    });

    setEditorStates(newEditorStates);

    // Update selected editor states
    if (selectedEditorStates.length > 0) {
      const selectedEditorStateIds = selectedEditorStates.map((s) => s.id);

      onSelectedEditorStatesChange(
        newEditorStates.filter((s) => selectedEditorStateIds.includes(s.id)),
      );
    }
  }

  async function handleOnSavePosts() {
    analyticsTrack('Assistant Command Saved', {
      command: command.identifier,
      save_type: 'postsets',
      subject_type: subject?.type || null,
    });
    setSaving(true);

    const promises = selectedEditorStates.map(async (editorStateItem) => {
      const message = JSON.stringify({
        entityMap: {},
        blocks: [
          {
            key: '59kd9',
            text: editorStateItem.editorState.getCurrentContent().getPlainText('\u0001'),
            type: 'unstyled',
            depth: 0,
            inlineStyleRanges: [],
            entityRanges: [],
            data: {},
          },
        ],
      });

      const inputCreatePostset = {
        message,
      };

      if (editorStateItem.scheduled_at) {
        inputCreatePostset.scheduled_at = editorStateItem.scheduled_at;
      }

      if (subject?.initialCampaigns) {
        inputCreatePostset.campaigns = {
          connect: subject.initialCampaigns.map(({ id }) => id) || [],
        };
      }

      const {
        data: { createPostset },
      } = await createPostsetMutation({
        variables: {
          input: inputCreatePostset,
        },
      });

      if (assetSource === 'url' && assetUrl && assetUrl.length) {
        await createLink({
          variables: {
            input: {
              url: appendUrlProtocol(assetUrl),
            },
          },
        })
          .then(async (result) => {
            await attachPostsetLink({
              variables: {
                id: createPostset.id,
                link_id: result.data.createLink.id,
              },
              context: { headers: { 'X-Lock-Token': createPostset.lock.token || null } },
            });
          })
          .catch(() => {
            toast.error('An unexpected error occurred while processing your request.', {
              toastId: 'flashMessage',
              autoClose: 5000,
            });
          });
      }

      if (
        assetSource === 'contentPieces' &&
        assetContentPieces.length &&
        assetContentPieces[0].__typename === 'Story'
      ) {
        await attachPostsetStory({
          variables: {
            id: createPostset.id,
            story_id: assetContentPieces[0].id,
          },
          context: { headers: { 'X-Lock-Token': createPostset.lock.token || null } },
        });
      }

      setEditorStates((prevEditorStates) => {
        const _editorStates = prevEditorStates.map((_item) => {
          if (_item.id === editorStateItem.id) {
            return {
              ..._item,
              entityId: createPostset.id,
            };
          }
          return _item;
        });
        return _editorStates;
      });
    });

    await Promise.all(promises);
    setSaving(false);
  }

  const componentClassName = classNames('command-result__transcript-item', {
    'command-result__transcript-item--bubble': !insidePopover,
    'command-result__transcript-item--role-user d-flex flex-gap-lg': item.role === 'user',
    'command-result__transcript-item--role-assistant': item.role === 'assistant',
    small: size === 'sm',
  });
  const componentContentClassName = classNames('command-result__transcript-item__content', {
    'd-flex flex-gap-lg': item.role === 'user',
  });

  return (
    <div className={componentClassName}>
      <div className={componentContentClassName}>
        {item.role === 'user' && (
          <div>
            <img
              className="img-circle"
              width={size === 'sm' ? 20 : 30}
              src={StoryChief.user.profile_picture}
              role="presentation"
              alt="profile avatar"
            />
          </div>
        )}
        {!!item.content && (
          <>
            {editorStates ? (
              editorStates.map((_item) => (
                <CommandResultTranscriptItemContent
                  key={_item.id}
                  id={_item.id}
                  loading={loading}
                  editorState={_item.editorState}
                  selectable={_item.selectable}
                  selected={isSelected(_item.id)}
                  onSelect={handleOnSelectContentEntity}
                />
              ))
            ) : (
              <CommandResultTranscriptItemContent
                id={item.id}
                editorState={editorState}
                onSelect={handleOnSelectContentEntity}
                loading={loading}
              />
            )}
            {!loading && !insidePopover && editorStateHasHeader && !isContentTypeChat && (
              <div className="w-100 space-top-2 space-2">
                <Button
                  className="w-100"
                  disabled={loadingStory || loading}
                  onClick={onCreateStory}
                  data-testid="powermode-assistant-transcript-open-in-story-editor"
                >
                  {loadingStory && <Icon icon="gg:spinner" inline className="mr-1 animate-spin" />}
                  Open in article editor
                </Button>
              </div>
            )}
            {!loading && selectedEditorStates.length > 0 && (
              <div className="command-result__transcript-item__content__actions">
                {createdEditorStates.length > 0 && !saving ? (
                  <div className="d-flex flex-column flex-gap-lg">
                    <Button onClick={handleOnViewSavedPosts}>View social posts</Button>
                  </div>
                ) : (
                  <div className="d-flex flex-column flex-gap-lg">
                    <div>
                      <div>
                        <strong>
                          <Icon icon="bi:clock" inline /> Schedule
                        </strong>
                      </div>
                      {selectedEditorStates.map((editorStateItem, index) => (
                        <div
                          className="pull-left gutter-1 w-1/2"
                          key={`schedule-${editorStateItem.id}`}
                        >
                          <DateRangePicker
                            minDate={getDateObjectWithTimezone()}
                            startDate={editorStateItem?.scheduled_at || getDateObjectWithTimezone()}
                            endDate={editorStateItem?.scheduled_at || getDateObjectWithTimezone()}
                            onChange={(changedStartDate) =>
                              handleOnContentEntityScheduledAtChange(
                                editorStateItem.id,
                                getISODate(changedStartDate),
                              )
                            }
                            side="bottom"
                          >
                            <div>
                              <span>{`Post ${index + 1}: `}</span>
                              <span className="text-underline cursor-pointer">
                                {editorStateItem?.scheduled_at
                                  ? formatDate(editorStateItem?.scheduled_at, 'long')
                                  : 'none'}
                              </span>
                            </div>
                          </DateRangePicker>
                        </div>
                      ))}
                    </div>
                    <Button disabled={saving} onClick={handleOnSavePosts}>
                      {saving && <Icon icon="gg:spinner" inline className="mr-1 animate-spin" />}
                      Save social posts
                    </Button>
                  </div>
                )}
              </div>
            )}
          </>
        )}
      </div>
      {item.content && item.role !== 'user' && (
        <div className="command-result__transcript-item__actions space-top-1">
          {loading ? (
            <div className="d-flex flex-align-items-center filter-transparent">
              <div className="d-flex flex-gap-lg flex-align-items-center flex-grow">
                <div>AI is writing</div>
                <div>
                  <div className="dot-falling" />
                </div>
              </div>
              <div className="d-flex flex-gap-lg">
                <Button bsStyle={null} className="btn-xs btn-chromeless" onClick={cancelCommand}>
                  ESC stop
                </Button>
              </div>
            </div>
          ) : (
            <>
              {!insidePopover && !selectedEditorStates.length && (
                <div className="w-100 d-flex flex-align-items-center">
                  <div className="flex-grow">
                    {lastItem && (
                      <ConditionalWrapper
                        condition={isOverMonthlyLimit}
                        wrapper={(children) => (
                          <OverlayTrigger
                            key="command-submit-overlay"
                            placement="top"
                            overlay={
                              <Tooltip id="command-submit-tooltip">
                                The monthly limit of {maxMonthlyUsage} has been reached. You can
                                upgrade to AI Power Mode Pro anytime in your billing center.
                              </Tooltip>
                            }
                          >
                            <PointerEventsWrapper>{children}</PointerEventsWrapper>
                          </OverlayTrigger>
                        )}
                      >
                        <div className="d-flex flex-gap-lg">
                          <Button
                            onClick={onRetry}
                            bsStyle={null}
                            className="btn-xs btn-chromeless"
                            disabled={isOverMonthlyLimit}
                          >
                            <span className="text-muted">Try again</span>
                          </Button>
                          {!command.excludeContinue && (
                            <Button
                              onClick={onContinue}
                              bsStyle={null}
                              className="btn-xs btn-chromeless"
                              disabled={isOverMonthlyLimit}
                            >
                              <span className="text-muted">Continue generating</span>
                            </Button>
                          )}
                        </div>
                      </ConditionalWrapper>
                    )}
                  </div>
                  {!isContentTypeChat && (
                    <div className="d-flex gutter-right-5" style={{ gap: 3 }}>
                      <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip id="tooltip-story">Open in article editor</Tooltip>}
                      >
                        <button
                          type="button"
                          className="btn-chromeless filter-transparent d-inline mr-1"
                          disabled={loadingStory || loading}
                          onClick={onCreateStory}
                        >
                          {loadingStory ? (
                            <Icon icon="gg:spinner" className="animate-spin" />
                          ) : (
                            <Icon icon="fa:file-text-o" />
                          )}
                        </button>
                      </OverlayTrigger>
                      <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip id="tooltip-social">Open in social post editor</Tooltip>}
                      >
                        <button
                          type="button"
                          className="btn-chromeless filter-transparent d-inline mr-1"
                          disabled={loadingPostset || loading}
                          onClick={onCreatePostset}
                        >
                          {loadingPostset ? (
                            <Icon icon="gg:spinner" className="animate-spin" />
                          ) : (
                            <Icon icon="custom:social" />
                          )}
                        </button>
                      </OverlayTrigger>
                      <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip id="tooltip-copy">Copy to clipboard</Tooltip>}
                      >
                        <CopyToClipboard
                          text={item.content}
                          onCopy={() => {
                            analyticsTrack('Assistant Command Saved', {
                              command: command.identifier,
                              save_type: 'clipboard',
                              subject_type: subject?.type || null,
                            });
                          }}
                        >
                          <button
                            type="button"
                            className="btn-chromeless filter-transparent d-inline"
                            disabled={loading}
                          >
                            <Icon icon="ion:documents-outline" />
                          </button>
                        </CopyToClipboard>
                      </OverlayTrigger>
                    </div>
                  )}
                </div>
              )}{' '}
            </>
          )}
        </div>
      )}
    </div>
  );
}

CommandResultTranscriptItem.propTypes = propTypes;
CommandResultTranscriptItem.defaultProps = defaultProps;
export default CommandResultTranscriptItem;
